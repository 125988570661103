export const WebAppApi = {
  saveContactUs: 'contactUsForm',
  getAllTestimonial: 'allTestimonials',
  emailVerification: 'user/verification',
  faqType: 'faq/getAllFaqTypes',
  getAllFaq: 'faq/getFaqByType',
  getOTP: 'common/sendOtp',
  verifyOTP: 'common/verifyOtp',
};

export const RegistrationApi = {
  signUp: 'user/registration',
  forgotPassword: 'user/forgotPassword',
  signIn: 'oauth/token',
  forgotpwdGetsecurityQuestion: 'securityQuestion/getSecurityQuestionByEmailId',
  resetPassword: 'user/resetPassword',
  validateSecurityQuestion: 'securityQuestion/validateSecurityQuestions',
  scmid: 'common/getDeviceDetails',
  removescmid: 'common/logout',
  getAllUserRole: 'common/userRoles',
};

export const AdminheaderApi = {
  profileLogo: 'user/getUserLogo',
};

export const CreateProfileApi = {
  country: 'common/country',
  state: 'common/state',
  city: 'common/city',
  role: 'common/roles',
  subRole: 'common/subRoles',
  businessType: 'businessType',
  industryType: 'industryType',
  securityQuestion: 'common/questions',
  saveProfile: 'userCompanyProfile/addUserCompanyProfile',
  getSavedProfile: 'userCompanyProfile/getUserCompanyProfile',
  getCompanyDetails: 'userCompanyProfile/getUserCompanyProfile',
  uploadLogo: 'uploadFile',
  uploadKYBDocument: 'uploadMultipleFiles',
  deleteDocument: 'deleteFile',
  advanceProfile: 'userCompanyAdvanceProfile/addUserCompanyAdvanceProfile',
  getAdvanceProfile: 'userCompanyAdvanceProfile/getUserCompanyAdvanceProfile'
};

export const BlogApi = {
  blogList: 'blogs',
};

export const UserList = {
  role: 'common/roles',
  subRole: 'common/subRoles',
  userList: 'user/getUserByRole',
  userActive: 'user/deactivateUser',
  deleteuser: 'user/deleteUser',
  viewUser: 'user/getUserDetails',
  userChangepassword: 'user/changePassword',
  userStatusUpdate: 'user/updateUserStatus',
  userDelete: 'user/deleteUser',
  export: 'export/users',
  getNotification: 'notification'
};

export const SecurityQuestion = {
  getSecurityQuestion: 'securityQuestion',
  saveSecurityQuestion: 'securityQuestion',
};

export const BusinessType = {
  getAllBusinessType: 'businessType/getAllBusinessTypes',
  addBusinessType: 'businessType',
  deleteBusinessType: 'businessType',
};

export const IndustryType = {
  getAllIndustryType: 'industryType/getAllIndustryTypes',
  addIndustryType: 'industryType',
  deleteIndustryType: 'industryType/deleteById',
};

export const AdminPricing = {
  getPricingType: 'pricing/types',
  getAllPricing: 'pricing',
  viewPricing: 'pricing/viewDetails',
  savePricing: 'pricing',
  getBuyer: 'common/getAllBuyers',
};

export const UserVerificationList = {
  userVerificationList: 'user/getAllUserRequests',
  userVerificationDetails: 'user/getUserRequest',
  resendEmail: 'email/mailTrigger',
  export: 'export/userVerification',
  downloadDocumenr: 'getFile',
  deleteDocument: 'deleteFile',
};

export const adminFinancialDocument = {
  getAllFinancialDocument: 'financialDocument/getAllFinancialDocument',
  addFinancialDocument: 'financialDocument',
  deleteFinancialDocument: 'financialDocument',
  documentTypeDropdown: 'financialDocument/getFinancialDocumentTypes'
};

export const signupQuestions = {
  getAllSignupQuestions: 'signUpQuestion',
};

export const siteSettings = {
  addsiteSetting: 'siteSettings',
  getSiteSetting: 'siteSettings',
  uploadLogo: 'uploadFile',
  downloadDocumenr: 'getFile',
  deleteDocument: 'deleteFile',
};

export const inquiries = {
  getInquiriesList: 'contactUsForm',
  deleteInquiries: 'contactUsForm',
  replyToUser: 'contactUsForm/replyToInquiry',
  viewInquiries: 'contactUsForm/viewInquiry',
};

export const earnings = {
  getAllEarnigs: 'common/earnings',
  getPricingType: 'pricing/types',
  earningsDownload: 'export/earnings',
};

export const FinancialDocuments = {
  financialDocumentList: 'userFinancialDocument/viewUserFinancialDocument',
  getAllFinancialDocumentNamesList: 'userFinancialDocument/viewActiveFinancialDocuments',
  resendEmail: 'email/mailTrigger',
  uploadKYBDocument: 'uploadMultipleFiles',
  adduploadFinancialDocument: 'userFinancialDocument/addUserFinancialDocument',
  export: 'export/userVerification',
  downloadDocumenr: 'getFile',
  deleteDocument: 'deleteFile',
  statusChangeDocument: 'userFinancialDocument/changeActiveStatus',
};

export const commonLogoUpdate = {
  getSiteSettingLogo: 'siteSettings',
};

export const BuyerTransacionBids = {
  getBuyerBidsList: 'bid',
  getType: 'financialDocument/getFinancialDocumentTypes',
  getBidStatus: 'common/getAllBidStatus',
  getAllInvoiceType: 'common/getAllInvoiceTypes',
  viewBids: 'bid/viewBidsByStatus',
  withdrawnBids: 'bid/viewWithDrawnBids',
  getViewBidsStatistics: 'bid/viewBidStatistics',
  addDealDecision: 'deal/addDealDecision',
  addDealDecisionBuyer: 'deal/dealDiscussionBuyer',
  getOfferDetails: 'deal/getOfferDetails',
  getFinalOfferDetails: 'deal/getFinalOfferDetails',
  changeDealStatus: 'deal/changeDealstatus',
  getInsurenceCompany: 'contract/getAllInsuranceCompanies',
  uploadLogo: 'uploadFile',
  addContractDetails: 'contract/addContract',
  contractDigitalSignature: 'contract/contractFileGeneration',
  getAllBuyerBids: 'bid/viewUserBid',
  getOtherUserBids: 'bid/viewOtherUserBids',
  getContractDocument: 'contract/getContractDetails',
  viewTransaction: 'fundRequestTransaction/viewTransactionDetails',
  addSignature: 'contract/addSignature',
  pricingAndValidation: 'pricing/pricingsAndValidation',
  getMakeaPaymentDetails: 'bid/payment',
  withdrawBids: 'bid/changeBidStatus',
  addRemoveFavouriteBuyers: 'favoriteBuyer/favourite',
  getSellerDealJourney: 'bid/sellerDealJourney?bidId=',
  getSellerDealJourneyAll: 'bid/sellerJourneyForMultipleBuyers?publishedTransactionId=',
  getBuyerDealJourney: 'bid/buyerDealJourney?bidId=',
  validateAccountOTP: 'xtrm/validateOtp',
  updateTransactionStatus: 'bid/changeTransactionStatus'
};

export const manageTransactions = {
  manageTransactionsList: 'fundRequestTransaction/getAllTransactionList',
  getTransactionsDetails: 'fundRequestTransaction/viewTransactionDetails',
  getAllTypes: 'financialDocument/getFinancialDocumentTypes',
  getAllInvoiceTypes: 'common/getAllInvoiceTypes',
  addTransaction: 'fundRequestTransaction',
  getAllSupplierAndBubyers: 'fundRequestTransaction/getCustomerOrSupplier',
  postforSale: 'fundRequestTransaction/postTransaction',
  withdrawTransaction: 'fundRequestTransaction/withdrawTransaction',
  bidsDetails: 'bid/viewUserBid',
  makeaBids: 'bid/addBid',
  addCustomerOrSupplier: 'fundRequestTransaction/addCustomerOrSupplier',
  getAllAssetsDocumnets: 'assetDocument/getAssetDocuments',
  deleteTransaction: 'fundRequestTransaction',
  getAllFinancialDocumentNames: 'financialDocument/getFinancialDocumentNames',
  pricingAndValidation: 'pricing/pricingsAndValidation',
  getAllAttributes: 'common/getAttribute',
  getQualityList: 'common/assetQuality',
  updateQualityList: 'fundRequestTransaction/updateAssetQuality',
};

export const accountSetting = {
  changePassword: 'user/changePassword',
  testimonial: 'testimonials',
  linkAccount: 'accountDetails',
  getAccountType: 'accountDetails/accountTypes',
  getBankName: 'accountDetails/bankNames',
  industryType: 'industryType',
  countryList: 'common/country',
  transCriteria: 'transactionCriteria',
  securityQuesetion: 'common/securityQuestions',
  viewSecurityQuesetion: 'securityQuestion/userSecurityQuestion',
  allEmailNotification: 'notification/settings',
  upateEmailNotification: 'notification',
  getBakList: 'xtrm/searchBank'
};

export const digitalWallet = {
  depositMoney: 'xtrm/walletFund',
  widthdrawMoney: 'xtrm/withdrawFund',
  depositHistory: 'xtrm/userWalletTransactions',
  widthdrawHistory: 'xtrm/withdrawFundHistory',
  walletBalance: 'xtrm/getWalletDetails',
  walletStatistics: 'digitalWallet/getWalletStatistics',
  walletMinMaxAmount: 'siteSettings/walletMinAmount',
  getBankList: 'accountDetails',
};

export const assetsMgmnt = {
  getList: 'assetDocument',
  addDocument: 'assetDocument',
  getAllDocumentTitle: 'assetDocument/getDocumentTitle',
  statusChangeDocument: 'assetDocument/changeStatus',
};

export const dynamicLabels = {
  getAllDynamicLabels: 'dynamicLabel',
  getAllActiveLabels: 'dynamicLabel/activeLabels',
};

export const favouriteBuyers = {
  getAllFavouriteBuyers: 'favoriteBuyer/getAllFavBuyers',
  deleteFavouriteBuyers: 'favoriteBuyer',
  addRemoveFavouriteBuyers: 'favoriteBuyer/favourite',
  industryType: 'industryType',
};

export const dashboard = {
  latesttransaction: 'fundRequestTransaction/getLatestTransactions',
  kpydatatransaction: 'common/statistics',
  toDolist: 'common/todoList'
};

export const paymentHistory = {
  manageTransactionsList: 'account/paymentHistory',
  downloadTransaction: 'export/paymentHistory'
};
