<div class="footer "
     *ngIf="isWebsite && currentURL != '/digitalXchange/register'">
  <div class="row footerMarginTop">
    <div class="col-md-3 col-lg-3 logo col-10 pl-4">
      <img class="width100 pb-3"
           src="../../assets/image/enabler-footer-log.svg">
    </div>
    <div class="col-md-6 col-lg-6 col-12 pr-5">
      <div class="row ml-5">
        <div class="col-md-4 col-4 offset-lg-2 offset-md-2">
          <span class="cursorPointer"
                [routerLink]="['/']">Home</span>
        </div>
        <div class="col-md-6 col-6">
          <span class="cursorPointer"
                [routerLink]="['/blogs']">Blog</span>
        </div>
      </div>
      <div class="row ml-5 mt-3">
        <div class="col-md-4 col-4 offset-lg-2 offset-md-2">
          <span class="cursorPointer"
                [routerLink]="['/aboutUs']">About Us</span>
        </div>
          <!--
        <div class="col-md-6 col-6">
          <span class="cursorPointer"
                [routerLink]="['/faq']">FAQ</span>
        </div>
          -->
      </div>
      <div class="row ml-5 mt-3">
        <div class="col-md-8 col-8 offset-lg-2 offset-md-2">
          <span class="cursorPointer"
                [routerLink]="['/contactUs']">Contact Us</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-lg-3 col-12 text-right followUs">
      <div class="col-md-12 col-lg-12 col-12 mb-2">
        <span class="cursorPointer">Follow Us</span>
      </div>
      <div class="col-md-12 col-lg-12 col-12 pl-md-0">
        <a href="">
          <img src="../../assets/image/Facebook_white.png">
        </a>
        <a href="https://www.youtube.com/channel/UCvi1YJvZa4fpYYWTNhFTetw"
           target="_blank">
          <img class="mx-3 "
               src="../../assets/image/youtube.png">
        </a>
        <a target="_blank"
           type="button"
           [ngbPopover]="popContent"> <img class="mr-3 "
               src="../../assets/image/linkedin.png"></a>
        <ng-template #popContent>
          <div> <a target="_blank"
               href="  https://www.linkedin.com/company/enablere"> Enablere</a></div>
          <div> <a target="_blank"
               href="https://www.linkedin.com/showcase/dxbyenablere"> Digital Xchange(dx)</a></div>
        </ng-template>
        <a href="">
          <img src="../../assets/image/twitter.png">
        </a>
      </div>
    </div>
  </div>
  <div class="border-bottom mx-4 mt-3"></div>
  <div class="row footerBg">
    <div class="col-12 col-md-12 col-lg-12 pl-5 mt-3 pb-3">
      <span class="copyRight">Copyright 2024 © Enablere. All rights reserved.</span>
    </div>
  </div>
</div>
