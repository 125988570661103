import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdleLogoutModalComponent } from 'src/app/popup-component/idle-logout/idle-logout.component';
import { mdModelOptions } from 'src/app/constants/modal-constant';
import { Location } from '@angular/common';
import { UtilitesService } from 'src/app/services/utilities.services';
import { AdminheaderService } from 'src/app/admin-app/adminheader/service/adminheader.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  timed: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  countdown: any;
  public currentURL: string;
  public hideHeader: boolean;
  public isWebsite: boolean;
  imgSrc: string = '../../../assets/image/arrow-down.png';
  imgSrc1: string = '../../../assets/image/arrow-down.png';
  modalRef: any;
  idealVal: any = 1800;
  countDownVal: any = 60;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private idle: Idle,
    private location: Location,
    public utilitesService: UtilitesService,
    public menuService: AdminheaderService
  ) {
    router.events.subscribe((val) => {
      this.currentURL = this.router.url;
    });
  }

  ngOnInit(): void {
    const userId = this.utilitesService.getLoggedInUserId();
    if (userId) {
      this.idleCall(0);
    }
    this.utilitesService.getUserLoggedIn.subscribe((data: boolean) => {
      if (data === true) {
        this.idleCall(1);
      }
    });

    this.utilitesService.getLoggedIn.subscribe((data: boolean) => {
      this.hideHeader = data;
    });
    this.hideHeader = (localStorage.getItem('loggedin') === 'true') ? true : false;

    this.utilitesService.getisWebsiteobj.subscribe((data: boolean) => {
      this.isWebsite = data;
    });

    this.isWebsite = (localStorage.getItem('isWebsite') === 'true') ? true : false;
    this.idle.setIdle(this.idealVal);
    this.idle.setTimeout(this.countDownVal);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      const loggedInUserId = this.utilitesService.getLoggedInUserId();
      if (loggedInUserId) {
        this.openModal();
      }
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      if (countdown === 1) {
        this.timed = true;
        this.closeModal();
      }
    });
  }

  idleCall(s): void {
    this.reset();
  }

  reset(): void {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  openModal(): void {
    this.modalRef = this.modalService.open(IdleLogoutModalComponent, mdModelOptions);
    this.modalRef.componentInstance.idleState = this.idleState;
    this.modalRef.componentInstance.countDown = this.countDownVal;
    this.modalRef.result.then(result => {
      if (result) {
        this.idle.stop();
        this.utilitesService.logout();
      } else {
        this.reset();
        this.refreshToken();
      }
    });
  }

  closeModal(): void {
    this.modalRef.close(true);
  }

  public setlocalStorage(): void {
    localStorage.setItem('loggedin', 'true');
    this.utilitesService.sendLoggedIn(true);
  }

  public refreshToken(): void {
    const userDetail: any = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetail) {
      const params: any = {
        client_id: 'digitx',
        client_secret: 'digitx-secret',
        grant_type: 'refresh_token',
        refresh_token: userDetail.refresh_token,
        username: userDetail.username,
      };
      this.menuService.getRefreshToken(params).subscribe((result: any) => {
        if (result.access_token) {
          localStorage.setItem('userDetails', JSON.stringify(result));
        }
      });
    }
  }

}
