import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdminheaderApi, UserList, RegistrationApi } from 'src/app/const/api.const';
import { UtilitesService } from 'src/app/services/utilities.services';
import { catchError } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
@Injectable()
export class AdminheaderService {

  constructor(
    private http: HttpClient,
    public utilitesService: UtilitesService) { }

  getUserLogo(): any {
    const url = environment.API_URL + AdminheaderApi.profileLogo;
    return this.http.get<any>(url).pipe(
      catchError(this.utilitesService.error)
    );
  }

  getUserRole(): any {
    const url = environment.API_URL + UserList.role;
    return this.http.get<any>(url).pipe(
      catchError(this.utilitesService.error)
    );
  }

  getNotification(): any {
    const url = environment.API_URL + UserList.getNotification;
    return this.http.get<any>(url).pipe(
      catchError(this.utilitesService.error)
    );
  }

  sendFCMid(params: any): any {

    const userdetails = JSON.parse(localStorage.getItem('userDetails'));

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userdetails.access_token
    });
    const url = environment.API_URL + RegistrationApi.scmid + '/' + params;
    return this.http.get<any>(url, { headers: headers }).pipe(
      catchError(this.utilitesService.error)
    );
  }
  removeFCMid(params: any): any {
    const url = environment.API_URL + RegistrationApi.removescmid + '/' + params;
    return this.http.get<any>(url, {}).pipe(
      catchError(this.utilitesService.error)
    );
  }

  getRefreshToken(params: any): any {
    const url = environment.API_URL + RegistrationApi.signIn + '?client_id=' + params.client_id +
      '&client_secret=' + params.client_secret + '&grant_type=' + params.grant_type +
      '&refresh_token=' + params.refresh_token + '&username=' + params.username;
    return this.http.post<any>(url, {}).pipe(
      catchError(this.utilitesService.error)
    );
  }

}
