import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { UtilitesService } from './services/utilities.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    private router: Router,
    private titleService: Title,
    private meta: Meta,
    public utilitesService: UtilitesService,
  ) {
  }

  ngOnInit(): void {

    this.utilitesService.getLoggedIn.subscribe((data: boolean) => {
      if (data) {
        this.utilitesService.getCommonLogo().subscribe((result) => {
          this.titleService.setTitle(result.payload.siteName);
          this.favIcon.href = 'data:image/png;base64,' + result.payload.siteFaviconByte;
        });

      } else {
        this.titleService.setTitle('Enablere');
        this.favIcon.href = '../assets/image/favicon-enablere.png';
      }
    });


    this.router.events.subscribe((event: RouterEvent) => {
      if ((event instanceof NavigationEnd)) {
        if (event.url === '/digitalXchange/signin') {
          this.utilitesService.sendLoggedIn(true);
          this.utilitesService.isWebsitefun(false);
        }
      }
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });


    this.utilitesService.getisWebsiteobj.subscribe((data: boolean) => {
      if (!data && localStorage.getItem('userDetails')) {
        localStorage.setItem('isWebsite', 'false');
      }
    });


  }
}
