// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  qa: false,

 // API_URL: 'https://digitalxchange-dev.zencode.guru/backend/de/',
  API_URL: "https://api.enablere.com/de/",
  captch_Key: '6Le9FlwaAAAAAEKSkFnsRZTXZ97eDtrUO4dAxQsd',
  /*
  firebase: {
    apiKey: 'AIzaSyC-R5Z3YErs3y7muWeLxf2dfSnGBH3euzg',
    authDomain: 'digitalexchangepush.firebaseapp.com',
    projectId: 'digitalexchangepush',
    storageBucket: 'digitalexchangepush.appspot.com',
    messagingSenderId: '259271522903',
    appId: '1:259271522903:web:296b2dbcaf94ea59760112',
    measurementId: 'G-G7BJ6RVM4P',
  }
     */
  firebase: {
    apiKey: "AIzaSyA5OhpTOmGEFudcRqGBdXc7FrbbZHUTMvQ",
    authDomain: "digitalxchange-608df.firebaseapp.com",
    projectId: "digitalxchange-608df",
    storageBucket: "digitalxchange-608df.appspot.com",
    messagingSenderId: "775777298135",
    appId: "1:775777298135:web:7ae842bfdd70b188dbb292",
    measurementId: "G-BZMQBRSK4J"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
