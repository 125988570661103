import { Component, OnInit } from '@angular/core';
import { ContactUsService } from '../contactus/service/contact-us.services';
import { ToastrService } from 'ngx-toastr';
import { UtilitesService } from 'src/app/services/utilities.services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  userToken: any;

  constructor(
    public verificationService: ContactUsService,
    public utilitesService: UtilitesService,
    public toastr: ToastrService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    // const url = window.location.pathname.split('/');
  }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe(parameter => {
      this.userToken = parameter.token;
    });
    const params: object = {
      id: this.userToken
    };

    this.verificationService.verifyUserEmail(params).subscribe((response: any) => {
      if (response.code === 200) {
        this.toastr.success('', response.message);
        localStorage.setItem('loggedin', 'true');
        this.utilitesService.sendLoggedIn(true);
        this.router.navigate(['/digitalXchange/signin']);
      } else if (response.code === 204) {
        this.toastr.error('', response.message);
        localStorage.setItem('loggedin', 'true');
        this.utilitesService.sendLoggedIn(true);
        this.router.navigate(['/digitalXchange/signin']);
      }
    });

  }


}
