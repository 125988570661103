<div class="modal-header">
  <div class="heading">Session Timeout</div>
  <!-- <button type="button" class="close" aria-label="Close" (click)="submit(false)">
        <span aria-hidden="true">&times;</span>
      </button> -->
</div>
<div class="modal-body">
  <div class="txt fnt-15 blockclr">
    Do you want to keep signed in ?
  </div>

  <div class="txt fnt-13 secndclr mart-10">
    You will Logout in {{ countDown }} seconds!
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          id="stay"
          class="btn btn-clr"
          (click)="submit(false)">
    Stay
  </button>
  <button type="button"
          id="logout"
          class="btn btn-outline-dark"
          (click)="submit(true)">
    Logout
  </button>
</div>