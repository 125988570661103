import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdminheaderService } from 'src/app/admin-app/adminheader/service/adminheader.service';

@Component({
  selector: 'app-idle-logout',
  templateUrl: './idle-logout.component.html',
  styleUrls: ['./idle-logout.component.scss']
})

export class IdleLogoutModalComponent implements OnInit {
  @Input() idleState: any;
  @Input() countDown: any = 0;
  public isStayLogin: boolean;
  interval: NodeJS.Timeout;
  constructor(
    public activeModal: NgbActiveModal,
    public adminService: AdminheaderService,
  ) { }

  ngOnInit(): void {
    this.isStayLogin = false;
    this.startCountdown();
  }

  submit(status: boolean): void {
    this.isStayLogin = status;
    this.activeModal.close(this.isStayLogin);
  }

  close(): void {
    this.activeModal.close(this.isStayLogin);
  }

  startCountdown(): void {
    this.interval = setInterval(() => {
      this.countDown--;

      if (this.countDown < 0) {
        clearInterval(this.interval);
        const fcmid = localStorage.getItem('fcmid');
        this.adminService.removeFCMid(fcmid).subscribe(
          (response: any) => {
            if (response) {
              localStorage.removeItem('fcmid');
            }
          },
          (error) => {
          }
        );
      }
    }, 1000);
  }

  OnDestroy(): void {
    clearInterval(this.interval);
  }
}
