import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitesService } from 'src/app/services/utilities.services';

@Component({
  selector: 'app-blogs-view',
  templateUrl: './blogs-view.component.html',
  styleUrls: ['./blogs-view.component.scss']
})
export class BlogsViewComponent implements OnInit {

  public postBlogsForm: FormGroup;
  constructor(
    public formBuild: FormBuilder, public utilitesService: UtilitesService
  ) {
    this.postBlogsForm = this.formBuild.group({
      userName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      comment: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    localStorage.setItem('loggedin', 'false');
    this.utilitesService.sendLoggedIn(false);
    localStorage.setItem('isWebsite', 'true');
    this.utilitesService.isWebsitefun(true);

  }

  get f(): any { return this.postBlogsForm.controls; }

}
