import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilitesService } from '../services/utilities.services';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DxAuthRedirectGuard implements CanActivate {
  constructor(private router: Router, private utilitesService: UtilitesService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    if (currentUser) {
      if (currentUser.defaultRole == null && currentUser.firstLogin === 'Yes') {
        this.router.navigate(['/digitalXchange/create-profile']);
      } else if (currentUser.firstLogin === 'Pending') {
        this.router.navigate(['/digitalXchange/create-profile/success-message']);
      } else if (currentUser.userTypeId !== 'f047d356-3c4e-4013-8be9-410e90d3c504' && currentUser.firstLogin === 'Yes') {
        this.router.navigate(['/digitalXchange/create-profile']);
      } else if (currentUser.userTypeId !== 'f047d356-3c4e-4013-8be9-410e90d3c504' && currentUser.firstLogin === 'No') {
        if ((currentUser.defaultRole).toLowerCase() === 'f047d356-3c4e-4013-8be9-410e90d3b502') {
          this.router.navigate(['/digitalXchange/dashboard']);
        } else {
          this.router.navigate(['/digitalXchange/generalDocuments']);
        }
      } else if (currentUser.userTypeId === 'f047d356-3c4e-4013-8be9-410e90d3c504') {
        this.router.navigate(['/digitalXchange/dashboard']);
      }
    }
    return true;
  }
}
