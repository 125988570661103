import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export { smModelOptions, mdModelOptions, lgModelOptions, xlModelOptions };

const smModelOptions: NgbModalOptions = {
  windowClass: 'draggableClass',
  scrollable: true,
  centered: true,
  // size: 'modal-sm',
  size: 'sm',
};

const mdModelOptions: NgbModalOptions = {
  windowClass: 'draggableClass',
  scrollable: true,
  centered: true,
  size: 'md',
  backdrop: 'static'
};

const lgModelOptions: NgbModalOptions = {
  windowClass: 'draggableClass',
  scrollable: true,
  centered: true,
  size: 'lg',
};

const xlModelOptions: NgbModalOptions = {
  windowClass: 'draggableClass',
  scrollable: true,
  centered: true,
  size: 'xl',
};
